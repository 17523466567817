/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';
import { BsEyeSlashFill, BsEyeFill } from 'react-icons/bs';
import { formatCurrency } from 'helpers/utils';
import Flex from 'components/common/Flex';
import axios from 'axios';
const API_URL = 'https://api.vossgi.com/v1/accounts';
import moment from 'moment';

const AccountSideBar = ({ data }) => {
  const [showBalance, setShowBalance] = useState(false);
  const [notes, setNotesData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchNotes = async () => {
    setLoading(true);

    try {
      const t = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/${data.id}/notes`, {
        headers: {
          Authorization: `Bearer ${t}`
        }
      });
      const resData = response.data?.data || [];

      console.log('resData', resData);
      setNotesData(resData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  const toggleBalanceVisibility = () => {
    setShowBalance(prevShowBalance => !prevShowBalance);
  };

  const formatDateWithDifference = date => {
    const formattedDate = moment(date).format('MM-DD-YYYY');
    const monthsAgo = moment().diff(moment(date), 'months');
    return `${formattedDate}
            ${monthsAgo} ${monthsAgo === 1 ? 'month' : 'months'} ago`;
  };
  return (
    <Card className="mb-3" style={{ minHeight: '450px' }}>
      <Row className="m-1">
        <Col sm={6} className="d-flex align-items-center mt-2">
          <span className="fs-9 text-body-emphasis">
            Balance:{' '}
            {showBalance ? `${formatCurrency(data.balance)}  ` : '******  '}
          </span>
          {showBalance ? (
            <BsEyeSlashFill
              className="mt-1 pl-2 cursor-pointer text-body-emphasis"
              onClick={toggleBalanceVisibility}
            />
          ) : (
            <BsEyeFill
              className="mt-1 pl-2 cursor-pointer text-body-emphasis"
              onClick={toggleBalanceVisibility}
            />
          )}
        </Col>
        <Col sm={6} className="d-flex justify-content-end">
          {/* <FaEdit className="mt-1 mr-2 fs-7 cursor-pointer text-body-emphasis" />
          <FaRegTrashAlt className="mt-1 fs-7 ml-2 cursor-pointer text-body-emphasis" /> */}
        </Col>
      </Row>
      <Row className="m-1 mt-3">
        <Col>
          <Flex direction="column">
            <h6 className="fw-semibold ls mb-2 text-uppercase border-bottom text-primary-emphasis">
              Notes
            </h6>
            <div className="mb-2">
              <p className="fw-bold mb-0 fs-10">Overdue Payments</p>
              <p className="text-muted fst-italic mb-0 fs-10">
                By Vahan Yoghoudjian at 12/5/2024 9:38 am
              </p>
            </div>
            <div className="mb-2">
              <p className="fw-bold mb-0 fs-10">Overdue Payments</p>
              <p className="text-muted fst-italic mb-0 fs-10">
                By Vahan Yoghoudjian at 12/5/2024 9:38 am
              </p>
            </div>
          </Flex>
        </Col>
      </Row>
      <Row className="m-1 mt-3">
        <Col>
          <Flex direction="column">
            <h6 className="fw-semibold ls mb-1 text-uppercase border-bottom text-primary-emphasis">
              Feeds
            </h6>
            <div>Feed data</div>
          </Flex>
        </Col>
      </Row>
      <Row className="m-1 mt-3">
        <Col>
          <Flex direction="column">
            <h6 className="fw-semibold ls mb-1 text-uppercase border-bottom text-primary-emphasis">
              Record Information
            </h6>
            <div className="d-flex justify-content-between w-100">
              <span className="fs-10">Created by Vahan Yoghoudjian</span>
              <span className="text-muted fst-italic fs-10">
                {formatDateWithDifference(data.created_at)}
              </span>
            </div>
            <div className="d-flex justify-content-between w-100">
              <span className="fs-10">Updated by Vahan Yoghoudjian</span>
              <span className="text-muted fst-italic fs-10">
                {formatDateWithDifference(data.updated_at)}
              </span>
            </div>
          </Flex>
        </Col>
      </Row>
    </Card>
  );
};

AccountSideBar.propTypes = {
  data: PropTypes.object.isRequired
};
export default AccountSideBar;
