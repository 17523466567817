/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Form, Col, Row, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { formatCurrency } from 'helpers/utils';
import CountUp from 'react-countup';
import classNames from 'classnames';

const Overview = ({ data }) => {
  const { isReadOnly, setIsReadOnly } = useState(true);
  const {
    name,
    legal_name,
    type,
    category,
    code,
    owner = null,
    credit_limit = null,
    jbt_date,
    jbt_number,
    jbt_rating,
    price_list = []
  } = data || {};

  return (
    <>
      <Row>
        <Col>
          <h6 className="fw-semibold ls mb-3 text-uppercase border-bottom text-primary-emphasis">
            General Information
          </h6>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Name</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="name"
              value={name}
              readOnly={isReadOnly}
            />
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Legal Name</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="legal_name"
              value={legal_name}
              readOnly={isReadOnly}
            />
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Type</Form.Label>
            <Form.Control
              size="sm"
              className="text-capitalize"
              type="text"
              name="type"
              value={type}
              readOnly={isReadOnly}
            />
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Category</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="category"
              value={category}
              readOnly={isReadOnly}
            />
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Code</Form.Label>
            <Form.Control
              size="sm"
              className="text-capitalize"
              type="text"
              name="code"
              value={code}
              readOnly={isReadOnly}
            />
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Owner</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="owner"
              value={owner}
              readOnly={isReadOnly}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6 className="fw-semibold ls mb-3 mt-3 text-uppercase border-bottom text-primary-emphasis">
            Accounting
          </h6>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Credit Limit</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="credit_limit"
              value={formatCurrency(credit_limit)}
              readOnly={isReadOnly}
            />
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Payment Method</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              value=""
              readOnly={isReadOnly}
            />
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Tax Group</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              value=""
              readOnly={isReadOnly}
            />
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Payment Term</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              value=""
              readOnly={isReadOnly}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6 className="fw-semibold ls mb-3 mt-3 text-uppercase border-bottom text-primary-emphasis">
            Price Group
          </h6>
          <Row className="g-4">
            {price_list?.map((el, idx) => {
              const variant =
                el.name === 'selling price' ? 'Secondary' : 'Light';
              return (
                <Col sm={6} lg={4} key={el.id}>
                  <Card
                    key={idx}
                    className={'text-bg-' + variant.toLowerCase()}
                  >
                    <Card.Body>
                      <Card.Title
                        className={classNames('text-capitalize', {
                          'text-white': variant !== 'Light',
                          'text-black': variant === 'Light'
                        })}
                        as="div"
                      >
                        {el.name}
                      </Card.Title>
                      <Card.Text className="text-end">
                        Markup{' '}
                        <CountUp end={el.markup} duration={2.75} suffix="%" />
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6 className="fw-semibold ls mb-3 mt-3 text-uppercase border-bottom text-primary-emphasis">
            Organizations
          </h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>JBT Number</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="jbt_number"
              value={jbt_number}
              readOnly={isReadOnly}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>JBT Date</Form.Label>
            <Form.Control
              size="sm"
              type="text"
              name="jbt_date"
              value={jbt_date}
              readOnly={isReadOnly}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>JBT Rating</Form.Label>
            <Form.Control
              size="sm"
              className="text-capitalize"
              type="text"
              name="jbt_rating"
              value={jbt_rating}
              readOnly={isReadOnly}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

Overview.propTypes = {
  data: PropTypes.object.isRequired
};

export default Overview;
