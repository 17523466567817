import React, { useState, useEffect, Suspense } from 'react';
import { Card, Form } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarExport
} from '@mui/x-data-grid';
import axios from 'axios';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';
import { formatCurrency, formatNumberWithCommas } from 'helpers/utils';
import SubtleBadge from 'components/common/SubtleBadge';

const API_URL = 'https://api.vossgi.com/v1/accounts';

const Accounts = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const navigate = useNavigate();

  const CustomExportButton = () => {
    const handleExport = async () => {
      let allData;
      try {
        const t = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}`, {
          headers: {
            Authorization: `Bearer ${t}`
          },
          params: {
            page: 0,
            per_page: 99999
          }
        });

        const columnFields = columns.map(col => col.field);

        allData = (response.data?.data || []).map(row => {
          const city = row.primary_location
            ? row.primary_location.city
            : 'No city';
          const state = row.primary_location
            ? row.primary_location.state
            : 'No state';
          const zip = row.primary_location ? row.primary_location.zip : '';

          const filteredRow = {};
          columnFields.forEach(field => {
            if (field === 'city') {
              filteredRow[field] = city;
            } else if (field === 'state') {
              filteredRow[field] = state;
            } else if (field === 'zipCode') {
              filteredRow[field] = zip;
            } else if (row[field] !== undefined) {
              filteredRow[field] = row[field];
            }
          });
          return filteredRow;
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      const csv = Papa.unparse(allData);
      const blob = new Blob([csv], { type: 'text/csv' });
      const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
      const fileName = `accounts_data_${timestamp}.csv`;
      saveAs(blob, fileName);
    };

    return (
      <IconButton
        variant="falcon-default"
        size="sm"
        icon="external-link-alt"
        transform="shrink-3"
        onClick={handleExport}
      >
        <span className="d-none d-sm-inline-block ms-1">Export All</span>
      </IconButton>
    );
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '10px'
        }}
      >
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <CustomExportButton />
      </GridToolbarContainer>
    );
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const t = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}`, {
        headers: {
          Authorization: `Bearer ${t}`
        },
        params: {
          page: page + 1,
          per_page: pageSize,
          ...(searchQuery && {
            search: searchQuery,
            search_fields:
              'code,name,balance,type,primaryLocation.city,primaryLocation.state,primaryLocation.city,primaryLocation.zip'
          })
        }
      });
      const transformedData = (response.data?.data || []).map(row => {
        const city = row.primary_location ? row.primary_location.city : ' ';
        const state = row.primary_location ? row.primary_location.state : ' ';
        const zip = row.primary_location ? row.primary_location.zip : '';
        return {
          ...row,
          city,
          state,
          zipCode: zip
        };
      });

      setData(transformedData);
      setRowCount(response.data?.meta?.total || 0);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize, searchQuery]);

  const handleSearchChange = event => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const handleRowClick = id => {
    navigate(`/accounts/account-details/${id}`);
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      renderCell: params => (
        <span
          style={{ cursor: 'pointer', color: '#007bff' }}
          onClick={() => handleRowClick(params.row.id)}
        >
          {params.value}
        </span>
      )
    },
    { field: 'code', headerName: 'Code', flex: 1 },
    { field: 'type', headerName: 'Type', flex: 1 },
    {
      field: 'balance',
      headerName: 'Balance',
      valueGetter: params => formatCurrency(params) || '',
      flex: 1
    },
    {
      field: 'city',
      headerName: 'City',
      flex: 1
    },
    {
      field: 'state',
      headerName: 'State',
      flex: 1
    },
    {
      field: 'zipCode',
      headerName: 'Zipcode',
      flex: 1
    },
    {
      field: 'active',
      headerName: 'Status',
      flex: 1,
      renderCell: params => (
        <SubtleBadge
          pill
          bg={`${params.value === 1 ? 'success' : 'danger'}`}
          className="fs-11 ml-1 fw-normal"
        >
          {params.value === 1 ? 'Active' : 'Inactive'}
        </SubtleBadge>
      )
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      flex: 2,
      renderCell: params => {
        const date = new Date(params.value);
        const formattedDate = date.toISOString().split('T')[0];
        const options = {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        };
        const formattedTime = new Intl.DateTimeFormat('en-US', options).format(
          date
        );
        return (
          <span>
            {formattedDate} {formattedTime}
          </span>
        );
      }
    }
  ];

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Card className="mb-3">
        <Card.Header className="mb-1 pb-1 d-flex justify-content-between">
          Accounts
          <span className="text-muted ms-auto">
            {formatNumberWithCommas(rowCount)}{' '}
            <span className="small">records found</span>
          </span>
        </Card.Header>
        <Card.Body className="pt-1">
          <div className="mb-1 flex">
            <Form.Group>
              <Form.Control
                type="text"
                name="filter"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Form.Group>
          </div>
          <div style={{ display: 'flex', height: '75vh', width: '100%' }}>
            <DataGrid
              className="fs-10"
              rows={data}
              columns={columns}
              pagination
              density="compact"
              pageSizeOptions={[100]}
              pageSize={pageSize}
              paginationMode="server"
              rowCount={rowCount}
              // onRowClick={e => handleRowClick(e.row.id)}
              loading={loading}
              onPaginationModelChange={pagination => {
                setPage(pagination.page);
                setPageSize(pagination.pageSize);
              }}
              slots={{
                toolbar: CustomToolbar
              }}
              sx={{
                '& .MuiDataGrid-columnHeaderTitle': {
                  color: '#2c7be5',
                  fontWeight: 'bold'
                },
                '& .MuiDataGrid-footerContainer': {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                },
                '& .MuiPagination-root': {
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                },
                '& .MuiDataGrid-toolbarContainer': {
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '2px',
                  color: 'black',
                  minHeight: '32px'
                },
                '& .MuiButtonBase-root': {
                  color: 'black',
                  minHeight: '24px'
                },
                '& .MuiSvgIcon-root': {
                  color: 'black',
                  fontSize: '1rem'
                },
                '& .MuiTablePagination-selectLabel': {
                  display: 'none', // Hides "Rows per page" label
                  paddingTop: '12px'
                },
                '& .MuiTablePagination-displayedRows': {
                  display: 'none', // Hides record count text
                  paddingTop: '12px'
                },
                '& .MuiTablePagination-select': {
                  display: 'none' // Hides "per page" dropdown
                },
                '& .MuiPagination-ul': {
                  justifyContent: 'flex-end' // Ensures pagination buttons align properly
                }
              }}
            />
          </div>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    </Suspense>
  );
};

export default Accounts;
