import React, { useState } from 'react';
import { Card, Col, Row, Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './Accounts.css';
import Overview from './tabsContent/Overview';
import Locations from './tabsContent/Locations';
import Contacts from './tabsContent/Contacts';
import Activity from './tabsContent/Activity';

const Tab4Content = () => <div>Orders Content</div>;
const Tab5Content = () => <div>Media</div>;

const AccountInfo = ({ data }) => {
  console.log(data);
  const [activeTab, setActiveTab] = useState('Overview');
  const renderTabContent = () => {
    switch (activeTab) {
      case 'Overview':
        return <Overview data={data} />;
      case 'Locations':
        return <Locations data={data} />;
      case 'Contacts':
        return <Contacts data={data} />;
      case 'Orders':
        return <Tab4Content />;
      case 'Media':
        return <Tab5Content />;
      case 'Activity':
        return <Activity data={data} />;
      default:
        return null;
    }
  };

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <Nav
              variant="tabs"
              activeKey={activeTab}
              onSelect={tab => setActiveTab(tab)}
              className="custom-tabs"
            >
              <Nav.Item>
                <Nav.Link eventKey="Overview">Overview</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Locations">Locations</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Contacts">Contacts</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Orders">Orders</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Media">Media</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Activity">Activity</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="bg-body-tertiary border-top">
        <Row>
          <Col lg xxl={12}>
            {renderTabContent(activeTab)}
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="border-top text-end">
        {/* <IconButton
          iconClassName="fs-11 me-1"
          variant="falcon-default"
          size="sm"
          icon="dollar-sign"
        >
          Refund
        </IconButton>
        <IconButton
          className="ms-2"
          iconClassName="fs-11 me-1"
          variant="falcon-default"
          size="sm"
          icon="check"
        >
          Save changes
        </IconButton> */}
      </Card.Footer>
    </Card>
  );
};

AccountInfo.propTypes = {
  data: PropTypes.object.isRequired
};

export default AccountInfo;
