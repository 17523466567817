import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Modal, Button } from 'react-bootstrap';
import Lottie from 'lottie-react';
import checkPrimaryLight from 'assets/img/animated-icons/check-primary-light.json';
import Flex from 'components/common/Flex';
import { FaLocationArrow } from 'react-icons/fa';

import axios from 'axios';

const Locations = ({ data }) => {
  console.log('data', data);
  const [locationData, setLocationData] = useState({});
  const [loading, setLoading] = useState({});
  const [modalInfo, setModalInfo] = useState({
    show: false,
    latitude: null,
    longitude: null
  });

  const handleShowModal = (latitude, longitude) => {
    setModalInfo({ show: true, latitude, longitude });
  };

  const handleCloseModal = () => {
    setModalInfo({ show: false, latitude: null, longitude: null });
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const t = localStorage.getItem('token');
      const response = await axios.get(
        `https://api.vossgi.com/v1/accounts/${data.id}/locations`,
        {
          headers: {
            Authorization: `Bearer ${t}`
          }
        }
      );
      console.log(response.data?.data);
      const sortedData = response.data?.data.sort((a, b) => {
        if (a.is_primary !== b.is_primary) {
          return b.is_primary - a.is_primary;
        }
        return a.name.localeCompare(b.name);
      });
      setLocationData(sortedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <>
      {locationData &&
        locationData.length > 0 &&
        locationData.map((child, index) => (
          <Row key={index}>
            <Col>
              <p className="text-dark mb-1 text-uppercase text-base fw-bolder">
                {child.name}
              </p>
              <p className="">
                {child.street}
                <br />
                {child.city}, {child.state} {child.zip}
                <br />
                {child.country}
              </p>
            </Col>
            <Col>
              <Col className="d-flex flex-column justify-content-center mt-md-4">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  {!!child.is_primary && (
                    <>
                      <Lottie
                        animationData={checkPrimaryLight}
                        loop={false}
                        style={{ height: '30px', width: '30px' }}
                      />
                      <p className="mb-0 flex-1">Primary Location</p>
                    </>
                  )}
                </Flex>
                <Flex justifyContent="center" alignItems="center">
                  {!!child.is_billing && (
                    <>
                      <Lottie
                        animationData={checkPrimaryLight}
                        loop={false}
                        style={{ height: '30px', width: '30px' }}
                      />
                      <p className="mb-0 flex-1">Billing</p>
                    </>
                  )}
                </Flex>
                <Flex justifyContent="center" alignItems="center">
                  {!!child.is_shipping && (
                    <>
                      <Lottie
                        animationData={checkPrimaryLight}
                        loop={false}
                        style={{ height: '30px', width: '30px' }}
                      />
                      <p className="mb-0 flex-1">Shipping</p>
                    </>
                  )}
                </Flex>
              </Col>
            </Col>
            <Col className="mt-md-4">
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  handleShowModal(child.latitude, child.longitude);
                }}
              >
                <FaLocationArrow /> View Location
              </a>
            </Col>
          </Row>
        ))}
      {/* Modal */}
      <Modal show={modalInfo.show} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: '50vh', width: '100%' }}>
            {modalInfo.latitude && modalInfo.longitude ? (
              <iframe
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ border: 0 }}
                loading="lazy"
                allowFullScreen
                src={`https://www.google.com/maps?q=${modalInfo.latitude},${modalInfo.longitude}&output=embed`}
              ></iframe>
            ) : (
              <p>Map loading...</p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Locations.propTypes = {
  data: PropTypes.object.isRequired
};

export default Locations;
