import React, { useState, useEffect, Suspense } from 'react';
import AccountDetailsHeader from './AccountDetailsHeader';
import AccountInfo from './AccountInfo';
import AccountSideBar from './AccountSideBar';
// import AccountLog from './AccountLog';
import { useParams } from 'react-router-dom';
import axios from 'axios';
const API_URL = 'https://api.vossgi.com/v1/accounts';
import { Container, Row, Col } from 'react-bootstrap';

const AccountDetails = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState({});
  const { accountId } = useParams();

  if (!accountId) {
    return <>No Account ID!!!</>;
  }
  const fetchData = async () => {
    setLoading(true);
    try {
      const t = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/${accountId}`, {
        headers: {
          Authorization: `Bearer ${t}`
        }
      });
      setData(response.data?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log(data);
  if (loading) {
    return <>Loading...</>;
  }
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AccountDetailsHeader data={data} />
      <Container fluid>
        <Row>
          <Col md={8} style={{ paddingLeft: 0 }}>
            <AccountInfo data={data} />
          </Col>
          <Col md={4} style={{ paddingRight: 0 }}>
            <AccountSideBar data={data} />
          </Col>
        </Row>
      </Container>
    </Suspense>
  );
};

export default AccountDetails;
