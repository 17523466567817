import React, { useState, useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { FaExternalLinkSquareAlt } from 'react-icons/fa';
import SubtleBadge from 'components/common/SubtleBadge';

const AccountDetailsHeader = ({ data }) => {
  const {
    // id,
    // code,
    name,
    type,
    active,
    website,
    // account_owner_id,
    // credit_limit,
    // balance,
    // price_group_id,
    // payment_method_id,
    // payment_term_id,
    // shipping_method_id,
    // sales_tax_id,
    // score,
    // jbt_number,
    // jbt_date,
    // jbt_rating,
    // website,
    // active,
    // created_at,
    // updated_at,
    primary_location,
    // locations
    media = null
  } = data || {};
  const {
    //   id: locationId,
    //   code: locationCode,
    //   name: locationName,
    //   type: locationType,
    //   account_id: locationAccountId,
    //   parent_id: locationParentId,
    //   is_primary,
    //   is_billing,
    //   is_shipping,
    //   email,
    phone,
    street,
    city,
    state,
    zip,
    country
    //   active: locationActive,
    //   created_at: locationCreatedAt,
    //   updated_at: locationUpdatedAt
  } = primary_location || {};

  const [timezone, setTimezone] = useState('');
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    async function fetchTimezone() {
      try {
        const response = await fetch(`https://api.zippopotam.us/us/${zip}`);
        const data = await response.json();

        if (data && data.places && data.places[0]) {
          const stateAbbreviation = data.places[0]['state abbreviation'];

          let timezoneName = 'America/Los_Angeles';

          switch (stateAbbreviation) {
            case 'CA': // California
            case 'OR': // Oregon
            case 'NV': // Nevada
            case 'ID': // Idaho
            case 'WA': // Washington
              timezoneName = 'America/Los_Angeles'; // Pacific Time
              break;
            case 'CO': // Colorado
            case 'NM': // New Mexico
            case 'UT': // Utah
            case 'WY': // Wyoming
            case 'AZ': // Arizona (note: some parts of AZ do not observe DST)
              timezoneName = 'America/Denver'; // Mountain Time
              break;
            case 'TX': // Texas
            case 'IL': // Illinois
            case 'WI': // Wisconsin
            case 'MI': // Michigan
            case 'MO': // Missouri
            case 'KS': // Kansas
            case 'OK': // Oklahoma
            case 'NE': // Nebraska
            case 'AR': // Arkansas
            case 'KY': // Kentucky
            case 'LA': // Louisiana
            case 'MN': // Minnesota
            case 'IN': // Indiana
            case 'ND': // North Dakota
            case 'SD': // South Dakota
            case 'IA': // Iowa
            case 'MS': // Mississippi
            case 'AL': // Alabama
            case 'TN': // Tennessee
            case 'OH': // Ohio
              timezoneName = 'America/Chicago'; // Central Time
              break;
            case 'NY': // New York
            case 'FL': // Florida
            case 'VA': // Virginia
            case 'CT': // Connecticut
            case 'RI': // Rhode Island
            case 'NJ': // New Jersey
            case 'DE': // Delaware
              timezoneName = 'America/New_York'; // Eastern Time
              break;
            default:
              timezoneName = 'America/Los_Angeles';
          }

          const now = moment().tz(timezoneName);
          const fullTimezoneName = new Intl.DateTimeFormat('en-US', {
            timeZone: timezoneName,
            timeZoneName: 'long'
          }).format(new Date());
          const offset = now.utcOffset() / 60;
          setTimezone(
            `${fullTimezoneName} (UTC${offset >= 0 ? '+' : ''}${offset})`
          );

          setCurrentTime(now.format('h:mm A'));
        }
      } catch (error) {
        console.error('Error fetching timezone data:', error);
        setTimezone('Timezone information unavailable');
        setCurrentTime('');
      }
    }

    if (zip) {
      fetchTimezone();
    }
  }, [zip]);

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="g-2">
          <Col>
            <p className="text-capitalize mb-0">
              {type}{' '}
              {active ? (
                <SubtleBadge pill bg="success" className="fs-10 ml-1 fw-normal">
                  Active
                </SubtleBadge>
              ) : (
                <SubtleBadge pill bg="danger" className="fs-10 ml-1 fw-normal">
                  Inactive
                </SubtleBadge>
              )}
            </p>
            <h1 className="mb-0 mt-0 pt-0 pb-0 ">
              <span>{name}</span>
            </h1>
            <p className="mt-0 pt-0 mb-0">
              <a
                href={`https://${website}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaExternalLinkSquareAlt /> {website}
              </a>
            </p>
            <p className="mt-0 pt-0">
              <h4>{phone}</h4>
            </p>
          </Col>
          <Col xs="auto">
            <div className="d-flex justify-content-end text-end pe-3">
              <div>
                {street}
                <br />
                {city}, {state} {zip}
                <br />
                {country}
              </div>
            </div>
            <div className="mt-3 d-flex justify-content-end text-end pe-3">
              {timezone && (
                <>
                  <p>
                    Local Time: {currentTime}
                    <br />
                    <span className="fst-italic">
                      {timezone.split(',')[1].trim()}
                    </span>
                  </p>
                </>
              )}
            </div>
          </Col>
          {media && (
            <Col xs="auto">
              <div className="d-flex justify-content-end text-end pe-3">
                <img
                  src={media}
                  alt={name}
                  style={{
                    maxWidth: '125px',
                    maxHeight: '125px'
                  }}
                ></img>
              </div>
            </Col>
          )}
        </Row>
      </Card.Header>
      {/* <Card.Body className="border-top">
        <Flex>
          <FontAwesomeIcon
            icon="user"
            className="text-primary me-2"
            transform="down-5"
          />
          <div className="flex-1">
            <p className="mb-0">Customer was created</p>
            <p className="fs-10 mb-0 text-600">{created_at}</p>
          </div>
        </Flex>
      </Card.Body> */}
    </Card>
  );
};

AccountDetailsHeader.propTypes = {
  data: PropTypes.object.isRequired
};

export default AccountDetailsHeader;
