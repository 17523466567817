import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Modal, Button, Table } from 'react-bootstrap';
import axios from 'axios';
import '../Timeline.css';
import Lottie from 'lottie-react';
import infiniteLoop from 'assets/img/animated-icons/infinite-loop.json';
import { formatCurrency, formatDate } from 'helpers/utils';

const Activity = ({ data }) => {
  const [activityData, setActivityData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ oldProps: {}, newProps: {} });

  const fetchData = async () => {
    setLoading(true);
    try {
      const t = localStorage.getItem('token');
      const response = await axios.get(
        `https://api.vossgi.com/v1/accounts/${data.id}/activity-logs`,
        {
          headers: {
            Authorization: `Bearer ${t}`
          }
        }
      );
      setActivityData(response.data?.data || []);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleShowDetails = (oldProps, newProps) => {
    setModalData({ oldProps, newProps });
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Lottie
          animationData={infiniteLoop}
          loop={true}
          style={{ height: '120px', width: '120px' }}
        />
      </div>
    );
  }

  return (
    <>
      <Row>
        <Col>
          <ul className="timeline_new">
            {activityData
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map((item, index) => {
                const { event, resource, updated_at, user, properties } = item;
                const userDisplayName =
                  typeof user === 'string'
                    ? user
                    : `${user?.first_name} ${user?.last_name}`;
                const oldProps = properties?.old;
                const newProps = properties?.attributes;

                return (
                  <li key={index}>
                    <div className="timeline-item-content">
                      <div className="timeline-item-card">
                        <h5 className="mb-2 fs-10">
                          <span className="text-capitalize">{event}</span>{' '}
                          {resource} by {userDisplayName}
                        </h5>
                        <p className="fs-10 mb-0">{updated_at}</p>
                        <Button
                          variant="link"
                          className="fs-10"
                          onClick={() => handleShowDetails(oldProps, newProps)}
                        >
                          More details
                        </Button>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Changes detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover size="sm" className="condensed-table">
            <thead>
              <tr>
                <th>Property</th>
                <th className="text-dark">Old Value</th>
                <th className="text-primary">New Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys({
                ...modalData.oldProps,
                ...modalData.newProps
              })
                .filter(key => {
                  const oldValue = modalData.oldProps?.[key];
                  const newValue = modalData.newProps?.[key];
                  const formattedOldValue =
                    key === 'credit_limit' || key === 'balance'
                      ? formatCurrency(oldValue)
                      : key === 'created_at' || key === 'updated_at'
                      ? formatDate(oldValue)
                      : oldValue;

                  const formattedNewValue =
                    key === 'credit_limit' || key === 'balance'
                      ? formatCurrency(newValue)
                      : key === 'created_at' || key === 'updated_at'
                      ? formatDate(newValue)
                      : newValue;

                  return formattedOldValue !== formattedNewValue;
                })
                .map(key => {
                  const oldValue = modalData.oldProps?.[key];
                  const newValue = modalData.newProps?.[key];
                  return (
                    <tr key={key}>
                      <td>{key}</td>
                      <td className="text-dark">
                        {key === 'credit_limit' || key === 'balance'
                          ? formatCurrency(oldValue)
                          : key === 'created_at' || key === 'updated_at'
                          ? formatDate(oldValue)
                          : oldValue || 'N/A'}
                      </td>
                      <td className="text-primary">
                        {key === 'credit_limit' || key === 'balance'
                          ? formatCurrency(newValue)
                          : key === 'created_at' || key === 'updated_at'
                          ? formatDate(newValue)
                          : newValue || 'N/A'}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

Activity.propTypes = {
  data: PropTypes.object.isRequired
};

export default Activity;
